import React from 'react'
import './App.css';
import { Routes, Route } from "react-router-dom";
import Header from "./Header"
import HomePage from "./HomePage"
import WatchPage from "./WatchPage"
import ChanelPage from "./ChanelPage"

const AppRoutes = () => {
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/watch/:chanelId" element={<ChanelPage />} />
        <Route path="/watch/:chanelId/:videoId" element={<WatchPage />} />
      </Routes>
    </div>
  )
}

export default AppRoutes;
