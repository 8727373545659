import React from 'react'
import { Link } from 'react-router-dom'
import { renderHTML } from './utils'
import TimeSincePublish from './TimeSincePublish'

const Video = ({video, chanelId}) => {
  const { videoId, publishedAt, title, thumbnail, duration, liveStreamingDetails} = video
  let livestream = false
  if(liveStreamingDetails) {
    const scheduledTime = Date.parse(liveStreamingDetails.scheduledStartTime)
    const now = Date.now()
    if(scheduledTime > now) {
      livestream = true
    }
  }
  let durationString = ""
  if(livestream) {
    durationString = "Upcoming"
  } else if(duration.hours > 0) {
    durationString = `${duration.hours}h`
  } else if(duration.minutes > 0) {
    durationString = `${duration.minutes}m`
  } else if(duration.seconds === "P0D") {
    durationString = "Live"
  } else {
    durationString = `${duration.seconds}s`
  }
  return (
    <Link to={`/watch/${chanelId}/${videoId}`} className="video">
      <div className="video-image-wrapper">
        <img src={thumbnail} alt="" loading="lazy" />
        <div>{durationString}</div>
      </div>
      <h5>{renderHTML(title)}</h5>
      <TimeSincePublish publishedAt={publishedAt}/>
    </Link>
  )
}

export default Video
