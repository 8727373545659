import React, { useRef, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useGlobal } from './ChanelContext'
import TimeSincePublish from "./TimeSincePublish"
import './WatchPage.css'
import { renderDescription } from './utils'

const WatchPage = () => {
  const {chanelId, videoId} = useParams()
  const videoRef = useRef()
  const {channels, videos} = useGlobal()

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  useEffect(() => {
    if(videoRef.current) {
      videoRef.current.contentWindow.document.body.focus()
    }
  }, [videoRef])

  if(!channels || !videos) {
    return <div>Loading</div>
  }

  const channel = channels.find(chanel => chanel.id === chanelId)
  if(!videos[channel.id]) {
    return <div>Loading</div>
  }
  const video = videos[channel.id].find(video => video.videoId === videoId)

  return (
    <div className="watch-page">
      <iframe title={`video.title`} 
        src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1&modestbranding=1&rel=0`} 
        allow="autoplay; 
        encrypted-media" 
        allowFullScreen 
        ref={videoRef}
        frameBorder="0">
      </iframe>
      <div className="watch-page-text">
        <h1>{video.title}</h1>
        <div style={{margin: "10px 0 0"}}>
          <TimeSincePublish publishedAt={video.publishedAt} />
        </div>
        <div style={{display: "flex"}}>
          <Link className="watch-page-chanel-link" to={`/watch/${channel.id}`}>
            <img src={channel.thumbnail} alt="" />
            <p>{channel.title}</p>
          </Link>
        </div>
        <div className="description">
          {renderDescription(video.description)}
        </div>
      </div>
    </div>
  )
}

export default WatchPage
