import React , {useState, useEffect} from 'react'

export const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

export const renderDescription = (description) => {
  const parsedDescription = 
    description
    .replace(/(https?:\/\/\S*)/g, "<a target='_blank' href='$1'>$1</a>")
    .replace(/(.*)\n/g, "<div>$1</div>")
    .replace(/(.*)\n/g, "<div>$1</div>")
    .replace(/<div><\/div>/g, "")
  return renderHTML(parsedDescription)
}

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

