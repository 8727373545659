import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import cross from './assets/cross_icon.png'

function ordinalSuffixOf(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

const Header = () => {
  const [data, setData] = useState(null)
  useEffect(() => {
    fetch("/dayData")
      .then(res => res.json())
      .then(json => setData(json))
      .catch(err => console.log(err)) 
  }, [])
  let colorLine = <div className="header-color-line" style={{backgroundColor: 'transparent'}}></div>
  let cels = data?.celebrations
  if(data) {
    // Find the celebration with the highest priority(lowest number) 
    let prioritizedCelebration = data.celebrations[0]
    data.celebrations.forEach(celebration => {
      if(celebration.rank_num < prioritizedCelebration.rank_num) {
        prioritizedCelebration = celebration
      }
    })
    cels = cels.sort((a, b) => a.rank_num - b.rank_num)
    colorLine = <div className="header-color-line" style={{backgroundColor: prioritizedCelebration?.colour}}></div>
  }
  return (
    <header>
      <Link to={"/"} style={{display: 'flex', alignItems: "center"}}>
        <img style={{margin: "5px 10px"}} src={cross} alt="Hands holding cross" />
        <h1>Vatican Tube</h1>
      </Link>
      <div className="header-info">
        {colorLine}
        {data && <Day date={data.date} title={data.weekday} season={data.season} seasonWeek={data.season_week} />}
        <div className="header-celebrations">
          {data && cels.map((celebration, index) => (
            celebration.title === "" ? "" :
              <div key={index} className="header-celebration" style={{borderColor: celebration.colour}}>{celebration.title}</div>
          ))}
        </div>
      </div>
    </header>
  )
}

const Day = ({date, title, season, seasonWeek}) => {
  const formattedSeason = season[0].toUpperCase() + season.substring(1)
  const formattedTitle = title[0].toUpperCase() + title.substring(1)
  return (
    <div className="header-day">
      <div className="header-day-date">
        <span>{formattedTitle}</span>
        {date}
      </div>
      <div className="header-day-season">
        <span>{ordinalSuffixOf(seasonWeek)} week of </span>
        {formattedSeason}
      </div>
    </div>
  )
}


export default Header
