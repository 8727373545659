import React, {useState, useEffect, createContext, useContext} from 'react'

const chanelContext = createContext({})

export const useGlobal = () => useContext(chanelContext)

export const ChanelProvider = (props) => {
  const [data, setData] = useState(null)
  const [channels, setChannels] = useState(null)
  const [videos, setVideos] = useState(null)
  useEffect(() => {
    (async () => {
      const res = await fetch("/channelData")
      const json = await res.json()
      setChannels(json)
    })()
  }, [])
  useEffect(() => {
    if(channels) {
      (async () => {
        channels.map(async channel => {
          const res = await fetch(`/videos/${channel.id}`)
          const json = await res.json()
          setVideos((prevVideos) => ({...prevVideos, [channel.id]: json}))
        })
      })()
    }
  }, [channels])
  return (
    <chanelContext.Provider value={{channels, videos}}>
      {props.children}
    </chanelContext.Provider>
  )
}

