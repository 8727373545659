import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGlobal } from './ChanelContext'
import Video from './Video'
import './ChanelPage.css'
import YoutubeLogo from './assets/youtube.png'
import { renderDescription } from './utils'

const ChanelPage = () => {
  const {chanelId} = useParams()
  const {channels, videos} = useGlobal()

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  if(!channels || !videos) {
    return <div>Loading</div>
  }

  const channel = channels.find(chanel => chanel.id === chanelId)
  if(!videos[channel.id]) {
    return <div>Loading</div>
  }
  const channelVideos = videos[channel.id]

  return (
    <div className="chanel-page">
      <div>
        <img src={channel.thumbnail} alt="" />
        <div className="chanel-page-title">
          <h1>{channel.title}</h1>
          <a className="chanel-page-youtube-link" href={`https://youtube.com/c/${channel.customUrl}`}><img src={YoutubeLogo} alt="Youtube Logo" /></a>
        </div>
        <div className="chanel-page-description description">
          {renderDescription(channel.description)}
        </div>
      </div>
      <h2>Videos</h2>
      <div className="videos">
        {channelVideos.map((video, index) => 
          <Video key={index} video={video} chanelId={channel.id} />)}
      </div>
    </div>
  )
}

export default ChanelPage
