import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGlobal } from "./ChanelContext"
import headerImage from './assets/clara.jpeg'

const tags = ["Priest", "Laymen", "News"]

const HomePage = () => {
  const {channels} = useGlobal()

  if(!channels) {
    return <div>Loading...</div>
  }

  return (
    <div className="home-page">
      <img src={headerImage} alt="Saint Clare of Assisi" />
      <ChannelPreviewWrapper chanels={channels} />
    </div>
  );
}

const TagButton = ({onClick, tag, active}) => {
  return (
    <button className={`tag${active ? ' tag-active' : ''}`} onClick={onClick}>{tag}</button>
  )
}

const ChannelPreviewWrapper = ({chanels}) => {
  const [filter, setFilter] = useState("")
  const filteredChannels = filter === "" ? chanels : chanels.filter(channel => channel.tags.includes(filter))
  return (
    <>
      <div>
        {tags.map(tag => (
          <TagButton 
            key={tag} 
            tag={tag} 
            active={filter === tag} 
            onClick={() => setFilter(filter === tag ? "" : tag)} />
        ))}
      </div>
      <div className="channel-previews-grid">
        {filteredChannels.map((chanel, index) => <ChannelPreview key={index} channel={chanel} />)}
      </div>
    </>
  )
}

const ChannelPreview = ({channel}) => {
  return (
    <div className="channel-preview">
      <Link to={`/watch/${channel.id}`}>
        <img src={channel.thumbnail} alt="" loading="lazy" />
        <h2>{channel.title}</h2>
      </Link>
    </div>
  )
}

export default HomePage
