import React from 'react'

const TimeSincePublish = ({publishedAt}) => {
  const publishedDate = new Date(publishedAt)
  const minutesSincePublish = Math.floor((new Date() - publishedDate) / (1000*60))
  const hoursSincePublish = Math.floor(minutesSincePublish / 60)
  const daysSincePublish = Math.floor(hoursSincePublish / 24)

  if(minutesSincePublish < 60) {
    return <div>{minutesSincePublish} minutes ago</div>
  }
  if(hoursSincePublish < 24) {
    return <div>{hoursSincePublish} hours ago</div>
  }
  if(daysSincePublish < 10) {
    return <div>{daysSincePublish} days ago</div>
  }

  return (
    <div>Published on {publishedDate.toLocaleDateString()}</div>
  )
}

export default TimeSincePublish
